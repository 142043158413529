<template>
  <div>
    <b-modal
      id="modal-material"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('SubmitYourMaterialVariantfor')} ` + item.name"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model.trim="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback"> {{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="`${$t('Code')}:`"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_5_characters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('TotalStockAmount')}:`"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="$v.form.total.$model"
            step="any"
            type="number"
            :state="validateState('total')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="`${$t('MinimalStockQuantity')}:`"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            v-model="$v.form.minimalQuantityStock.$model"
            step="any"
            type="number"
            :state="validateState('minimalQuantityStock')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="`${$t('PricePerMeter')}:`"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="$v.form.pricePerMeter.$model"
            required
            type="number"
            step="any"
            :state="validateState('pricePerMeter')"
            aria-describedby="input-1-live-feedback"
            placeholder="€"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="`${$t('SellPrice')}:`"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="form.sellPrice"
            required
            type="number"
            step="any"
            aria-describedby="input-1-live-feedback"
            placeholder="€"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-6"
          :label="`${$t('Daystodeliver')}:`"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            v-model="$v.form.daysToDeliver.$model"
            type="number"
            required
            :placeholder="$t('Days')"
            :state="validateState('daysToDeliver')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-select-1"
          :label="`${$t('MainSupplier')}:`"
          label-for="select-1"
        >
          <vue-select
            v-model="form.supplierId"
            :options="getFilteredSupplierInSelect.length == 0 ? getSuppliers: getFilteredSupplierInSelect"
            :reduce="e => e.supplierId"
            :placeholder="$t('Main') "
            label="supplierName"
            required
            :state="validateState('supplierId')"
            @search="onSearchSuppliers"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center"
            >
              <button
                type="button"
                :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == 1"
                @click="prevSuppliers"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageP == Math.ceil(getTotalItemsForSuppliers / 10) ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == Math.ceil(getTotalItemsForSuppliers / 10)"
                @click="nextSuppliers"
              >
                {{ $t('Next') }}
              </button>
            </li>
          </vue-select>
          <!-- :state="validateState('supplierId')"
            aria-describedby="input-1-live-feedback" -->
          <span
            v-show="formSubmitted && !$v.form.supplierId.required"
            style="color: red"
          >{{ $t('SelectMainSupplier') }}</span>
        </b-form-group>
        <b-form-group
          id="input-select-2"
          :label="`${$t('SecondarySupplier')}:`"
          label-for="select-2"
        >
          <vue-select
            v-model="form.supplierTwoId"
            :options="getFilteredSupplierInSelect.length == 0 ? getSuppliers: getFilteredSupplierInSelect"
            :reduce="e => e.supplierId"
            :placeholder="$t('Main') "
            label="supplierName"
            @search="onSearchSuppliers"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center"
            >
              <button
                type="button"
                :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == 1"
                @click="prevSuppliers"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageP == Math.ceil(getTotalItemsForSuppliers / 10) ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == Math.ceil(getTotalItemsForSuppliers / 10)"
                @click="nextSuppliers"
              >
                {{ $t('Next') }}
              </button>
            </li>
          </vue-select>
          <!-- :state="validateState('supplierTwoId')"
            aria-describedby="input-1-live-feedback" -->

        </b-form-group>
        <b-form-group :label="`${$t('SelectColor')}:`">
          <vue-select
            v-model="$v.form.colorDataId.$model"
            :options="getColorsData"
            :reduce="e => e"
            :placeholder="$t('SelectColor') "
            :state="validateState('colorDataId')"
            required
            label="colorName"
            @search="onSearchColors"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center; -ms-word-break: break-all; word-break: break-all; word-break: break-word;"
            >
              <button
                type="button"
                :style="pageC == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageC == 1"
                @click="prevColors"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageC == Math.ceil(getTotalItemsForColors / 10) ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageC == Math.ceil(getTotalItemsForColors / 10)"
                @click="nextColors"
              >
                {{ $t('Next') }}
              </button>
            </li>
            <!-- :state="validateState('colorName')"
            aria-describedby="input-1-live-feedback" -->
            <template #selected-option="{ colorName, hashColor }">
              <div class="flex aic">
                <span
                  class="colored-circle"
                  :style="`background-color: ${hashColor}`"
                />
                <p style="-ms-word-break: break-all; word-break: break-all; word-break: break-word; margin-bottom: 0; margin-left: 10px">
                  {{ colorName }}
                </p>
              </div>
            </template>

            <template #option="{ colorName, hashColor }">
              <div class="flex aic">
                <span
                  class="colored-circle"
                  :style="`background-color: ${hashColor}`"
                />
                <p style="-ms-word-break: break-all; word-break: break-all; word-break: break-word; margin-bottom: 0; margin-left: 10px">
                  {{ colorName }}
                </p>
              </div>
            </template>
          </vue-select>
          <span
            v-show="formSubmitted && !$v.form.colorDataId.required"
            style="color: red"
          >{{ $t('SelectColor') }}</span>
        </b-form-group>
        <b-form-group
          v-if="client !='DrenushaXharra'"
          id="input-group-4"
          :label="`${$t('Location')}:`"
        >
          <vue-select
            v-model="form.locationId"
            :options="getLocationByPagination"
            :placeholder="$t('SelectLocation')"
            :reduce="(e)=> e.locationId"
            label="locationName"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('MeasurementType')}:`"
        >
          <div class="flex radio-form">
            <div class="flex aic">
              <input
                id="one"
                v-model="item.measurementType"
                type="radio"
                value="Meter"
                disabled
              >
              <label for="one">{{ $t('Meter') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="two"
                v-model="item.measurementType"
                type="radio"
                disabled
                value="Part"
              >
              <label for="two">{{ $t('Part') }}</label>
            </div>
          </div>
          <div class="flex radio-form">
            <div class="flex aic">
              <input
                id="one"
                v-model="item.measurementType"
                type="radio"
                value="2"
                disabled
              >
              <label for="one">{{ $t('Package') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="two"
                v-model="item.measurementType"
                type="radio"
                disabled
                value="3"
              >
              <label for="two">{{ $t('BeadedStrand') }}</label>
            </div>
          </div>
          <div class="flex radio-form">
            <div class="flex aic">
              <input
                id="one"
                v-model="item.measurementType"
                type="radio"
                value="4"
                disabled
              >
              <label for="one">{{ $t('Set') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="two"
                v-model="item.measurementType"
                type="radio"
                disabled
                value="5"
              >
              <label for="two">{{ $t('Pair') }}</label>
            </div>
          </div>
        </b-form-group>
      </b-form>
      <AddFile
        ref="addFile"
        :folder="'MaterialVariantPhoto'"
        :label-text="$t('UploadPhoto')"
        :file-name="form.fileName"
        style="width: 200px !important;"
      />
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          class="buttonSubmit"
          :disabled="loading"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t('Submit') }}
        </button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, minValue, numeric,
} from 'vuelidate/lib/validators';
import AddFile from './AddFile.vue'
import { client } from '../domainConfig';



export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['materials', 'materialEditItem', 'measureItem', 'materialName', 'item'],

  data() {
    return {
      loading: false,
      formSubmitted: false,
      client: client.clientName,
      page: 1,
      pageP: 1,
      pageC: 1,
      location: null,
      pageSize: 15,
      totalItems: '',
      options: [],
      selected: null,
      colors: [],
      isActive: true,
      supplierName: null,
      contactName: null,
      form: {
        fileName: [],
        materialId: '',
        supplierId: null,
        colorDataId: '',
        supplierTwoId: null,
        name: '',
        description: '',
        measurementType: '',
        total: '',
        minimalQuantityStock: '',
        pricePerMeter: '',
        sellPrice: null,
        daysToDeliver: '',
        locationId: null,
      },

    }
  },
  validations: {
    form: {
      supplierId: {
        required,
      },
      // supplierTwoId: {
      //   required,
      // },
      // colorName: {
      //   required,
      // },
      name: {
        required,
        // minLength: minLength(3),
        // alpha,
      },
      description: {
        // required,
        // minLength: minLength(5),
        // alpha,
      },
      measurementType: '',
      total: {
        required,
        // numeric,
        minValue: minValue(0),
      },
      minimalQuantityStock: {
        required,
        // numeric,
        minValue: minValue(0),
      },
      colorDataId: {
        required,
      },

      pricePerMeter: {
        required,
        minValue: minValue(0),
        // numeric,
      },
      daysToDeliver: {
        required,
        minValue: minValue(0),
        numeric,
      },

    },
  },
  computed: {
    ...mapGetters(['getColorsData', 'getTotalItemsForColors', 'getCurrentPageColors', 'getSuppliers', 'getTotalItemsForSuppliers', 'getCurrentPageForSuppliers', 'getFilteredSupplierInSelect', 'getLocationByPagination']),
  },
  mounted() {
    // this.getSupplierList()
    // this.loadColors()
  },
  methods: {
    ...mapActions(['loadColors', 'loadSuppliers', 'loadFilSByName', 'resetFilS', 'loadColorsByName']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChange(event) {
      const files = event.target.files;
      if (files.length <= 3) {
        this.fileData = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });
          promise.then((fileData) => {
            this.fileData.push(fileData);
          });
          console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    async onSubmit() {
      this.formSubmitted = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.loading = true;
        // eslint-disable-next-line no-restricted-syntax
        await this.$refs.addFile.uploadFile();
        this.form.materialId = this.item.materialId
        this.form.measurementType = this.item.measurementType
        this.form.colorDataId = this.form.colorDataId.id
        console.log(this.item.materialId)
        this.$emit('materialvariant', this.form)
        this.$refs.modal.hide()
        setTimeout(() => {
          this.resetForm()
        }, 10)
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        this.fileName = [];
      }
      // this.form.reset();
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    resetForm() {
      // Reset our form values
      this.form.materialId = ''
      this.form.supplierTwoId = null
      this.form.supplierId = null
      this.form.name = ''
      this.form.description = ''
      this.form.total = ''
      this.form.colorName = ''
      this.form.colorDataId = ''
      this.form.minimalQuantityStock = ''
      this.form.pricePerMeter = ''
      this.form.sellPrice = ''
      this.form.daysToDeliver = ''
      this.form.locationId = null
      this.fileData = []
      this.form.fileName = []
      this.formSubmitted = false
      this.loading = false;
      // Trick to reset/clear native browser form validation state
    },
    async getSupplierPaginated(page, pageS) {
      await this.loadSuppliers({
        pageNumber: page,
        pageSize: pageS,
        supplierName: null,
        contactName: null,
        isActive: true,
      });
    },
    async getColorsPaginated(page, pageS) {
      await this.loadColors({
        colorName: '',
        pageNumber: page,
        pageSize: pageS,
      });
    },

    nextSuppliers() {
      if (this.pageP < this.getTotalItemsForSuppliers / 10) {
        this.pageP += 1;
        this.getSupplierPaginated(this.pageP, this.pageSize);
      }
    },
    prevSuppliers() {
      if (this.pageP > 1) {
        this.pageP -= 1;
        this.getSupplierPaginated(this.pageP, this.pageSize);
      }
    },
    nextColors() {
      if (this.pageC < this.getTotalItemsForColors / 10) {
        this.pageC += 1;
        this.loadColors({
          colorName: '',
          pageSize: 15,
          pageNumber: this.pageC,
        });
      }
    },
    prevColors() {
      if (this.pageC > 1) {
        this.pageC -= 1;
        this.loadColors({
          colorName: '',
          pageSize: 15,
          pageNumber: this.pageC,
        });
      }
    },
    async onSearchSuppliers(query) {
      await this.loadFilSByName({ companyName: query, isActive: this.isActive });
      if (query == '' || query == null) {
        this.resetFilS()
        this.getSupplierPaginated(this.getCurrentPageForSuppliers, this.pageSize);
      }
    },
    async onSearchColors(query) {
      await this.loadColors({ colorName: query, pageNumber: 1, pageSize: 15 });
      if (query == '') {
        this.loadColors({ colorName: query, pageNumber: this.getCurrentPageColors, pageSize: 15 });
      }
    },
  },

}
</script>

<style lang="scss" scoped>

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
